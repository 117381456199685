<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19.9958 15.7482C21.8116 14.4836 23 12.3806 23 10C23 6.13401 19.866 3 16 3C12.134 3 9 6.13401 9 10C9 12.3806 10.1884 14.4836 12.0042 15.7482C7.90463 17.3473 5 21.3344 5 26C5 26.5523 5.44772 27 6 27C6.55228 27 7 26.5523 7 26C7 21.0294 11.0294 17 16 17C20.9706 17 25 21.0294 25 26C25 26.5523 25.4477 27 26 27C26.5523 27 27 26.5523 27 26C27 21.3344 24.0954 17.3473 19.9958 15.7482ZM11 10C11 7.23858 13.2386 5 16 5C18.7614 5 21 7.23858 21 10C21 12.7614 18.7614 15 16 15C13.2386 15 11 12.7614 11 10Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconPerson",
});
</script>
